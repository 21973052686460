import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'redux-form';
import { Query } from 'react-apollo';
import { get } from 'lodash';
import TextField from '../TextField';
import CheckboxGroup from '../CheckboxGroup';
import RadioButtonGroup from '../RadioButtonGroup';
import ApplicationActions from '../ApplicationActions';
import SchoolSelectInput from '../SchoolSelectInput';
import { allSchoolsQuery } from '../../apollo';
import sortGradesServed from '../../helpers/sortGradesServed';
import {
  required,
  phone,
  email,
  applicationTerm,
  normalizePhone,
  normalizePercent,
  formatPercent,
  parseNumber,
  validateEnrollmentNumbers,
  uniqueTeachersAidTeamMember,
} from '../../helpers';
import getLabel from '../../helpers/getLabel';
import DistrictDropDownMenu from '../DistrictDropDownMenu';
import TextArea from '../TextArea';
import DropDownMenu from '../DropDownMenu';
import CountyList from '../../constants/CountyList';

const ApplicationTeachersAidStepOne = (props) => {
  const {
    data,
    save,
    handleSubmit,
    change,
    readOnly,
    termsAvailable,
    termsForYear,
    configTeachersAid,
    invalid,
    submitFailed,
    enums: {
      gradesServed,
      schoolTypes,
      termTypes,
    },
  } = props;

  const [schoolSelectionState, setSchoolSelectionState] = useState({
    districtId: null,
    schoolType: null,
    shouldFetch: false,
    hasFetched: false
  });

  const {
    schoolEnabled,
    summerEnabled,
  } = configTeachersAid.configTeachersAid;

  useEffect(() => {
    if (data && data.applicationTeachersAid && data.applicationTeachersAid.school && 
      data.applicationTeachersAid.school.districtId && data.applicationTeachersAid.school.type){
      setSchoolSelectionState({
        districtId: data.applicationTeachersAid.school.districtId,
        schoolType: data.applicationTeachersAid.school.type,
        shouldFetch: false,
        hasFetched: false
      })
    }
  },[data && data.applicationTeachersAid && data.applicationTeachersAid.school])

  const handleDistrictChange = (event, newValue) => {
    setSchoolSelectionState(prev => {
      const shouldFetch = Boolean(newValue && prev.schoolType) && !prev.hasFetched;
      return {
        ...prev,
        districtId: newValue,
        shouldFetch: true,
        hasFetched: shouldFetch ? true : prev.hasFetched
      };
    });
    change('school.name', null);
  };

  const handleSchoolTypeChange = (event, newValue) => {
    setSchoolSelectionState(prev => {
      const shouldFetch = Boolean(newValue && prev.districtId) && !prev.hasFetched;
      return {
        ...prev,
        schoolType: newValue,
        shouldFetch: true,
        hasFetched: shouldFetch ? true : prev.hasFetched
      };
    });
    return change('school.name', null);
  };

  const termOptions = termsForYear.map(({ termId, type, termFormatted }) => ({
    value: termId,
    label: (
      <span>
        {getLabel(termTypes.options, type)}
        <span className="term-text">{termFormatted}</span>
      </span>
    ),
  }));

  const renderSchoolSelect = ({ loading, error, data }) => {
    const schoolOptions = get(data, 'allSchools', []).map(school => ({
      school,
      label: school.name,
    }));
    
    return (
      <Field
        label={<label htmlFor="First" className="field-label">School Name</label>}
        component={SchoolSelectInput}
        options={schoolOptions}
        validate={required}
        districtId={schoolSelectionState.districtId}
        schoolType={schoolSelectionState.schoolType}
        change={change}
        loading={loading}
        name="school.name"
        placeholder={
          loading ? 'Loading schools...' :
          error ? 'Error loading schools' :
          'Select a school'
        }
        className="text-field w-input"
        disabled={readOnly || !termsAvailable || loading}
      />
    );
  };

  return (
    <Form onSubmit={handleSubmit(save)} id="email-form" className="program-form">
      <h4 className="purple-headline boxed-in">Application Type</h4>
    {
      !termsForYear.length ? (
        <span style={{ color: 'red' }}>
          We are not currently accepting applications for this year.
        </span>
      ) : (
        <Field
          name="termId"
          options={termOptions}
          component={RadioButtonGroup}
          className="radio-field w-radio"
          disabled={readOnly || !termsAvailable}
          validate={value => applicationTerm(value, termsForYear, summerEnabled, schoolEnabled)}
        />
      )
    }
    {(!(data && data.applicationTeachersAid && (data.applicationTeachersAid.status === 'APPROVED')) && !termsAvailable && Boolean(termsForYear.length)) && (
      <span style={{ color: 'red', display: 'block' }}>
        We are not currently accepting new applications.
      </span>
    )}
      <div className="site-information">
        <h4 className="purple-headline boxed-in">Initial School Information</h4>
        <div className="radio-question">
          <label htmlFor="First" className="field-label">Type of School:</label>
          <Field
            name="school.type"
            options={schoolTypes.options}
            component={RadioButtonGroup}
            className="radio-field inline-radio w-radio"
            validate={required}
            disabled={readOnly || !termsAvailable}
            onChange={handleSchoolTypeChange}
          />
        </div>

        <div className="field-combo-wrapper dropdown-select">
        <div className="field-combo">
          <Field
            name="school.districtId"
            className="text-field w-select"
            component={DistrictDropDownMenu}
            label={<label htmlFor="First-3" className="field-label">School District</label>}
            validate={required}
            disabled={readOnly || !termsAvailable}
            onChange={handleDistrictChange}
          />
        </div>
        </div>
      </div>
      {(schoolSelectionState.districtId && schoolSelectionState.schoolType) && (
      <>
        <div className="site-information">
          <h4 className="purple-headline boxed-in">School Selection</h4>
          <div className="field-combo-wrapper dropdown-select">
          <div className="field-combo">
          <Query 
            query={allSchoolsQuery}
            variables={{
              districtId: schoolSelectionState.districtId,
              schoolType: schoolSelectionState.schoolType
            }}
            
            fetchPolicy="cache-first"
            
          >
            {renderSchoolSelect}
          </Query>
        </div>
          </div>
        </div>
      <div className="site-information">
        <h4 className="purple-headline boxed-in">School Information</h4>
        <div className="radio-question">
          <label htmlFor="First-3" className="field-label">Did your school participate in this program last year?</label>
          <Field
            name="new"
            options={[
              {
                value: false,
                label: 'Yes',
              },
              {
                value: true,
                label: 'No',
              },
            ]}
            component={RadioButtonGroup}
            className="radio-field inline-radio w-radio"
            parse={val => val === 'true'}
            disabled={readOnly || !termsAvailable}
            validate={required}
          />
        </div>
        
        <div className="textarea-combo">
          <div className="field-combo">
            <Field
              label={<label htmlFor="First-3" className="field-label">Organization Type</label>}
              component={TextField}
              name="organizationType"
              className="text-field w-input"
              placeholder=""
              disabled={readOnly || !termsAvailable}
            />
          </div>
          <div className="field-textarea half">
            <Field
              name="nonProfitOrganizations"
              component={TextArea}
              maxLength={255}
              className="field-textarea w-input"
              label={<label htmlFor="First-3" className="field-label">If you are a 501(c)(3) organization, please list the schools and/or school district (including address), you are partnered with below. If you are a school, please skip this section.</label>}
              disabled={readOnly || !termsAvailable}
            />
          </div>
        </div>
        <div className="field-combo-wrapper">
          <div className="field-combo">
            <Field
              label={<label htmlFor="First-3" className="field-label">Address Line 1</label>}
              component={TextField}
              validate={required}
              name="school.address1"
              className="text-field w-input"
              disabled={readOnly || !termsAvailable}
            />
          </div>
          <div className="field-combo">
            <Field
              label={<label htmlFor="Last-3" className="field-label">Address Line 2 (optional)</label>}
              component={TextField}
              name="school.address2"
              className="text-field w-input"
              disabled={readOnly || !termsAvailable}
            />
          </div>
        </div>
        <div className="field-combo-wrapper">
          <div className="field-combo">
            <Field
              label={<label htmlFor="First-3" className="field-label">City</label>}
              component={TextField}
              validate={required}
              name="school.city"
              className="text-field w-input"
              disabled={readOnly || !termsAvailable}
            />
          </div>
          <div className="field-combo">
            <Field
              label={<label htmlFor="Last-3" className="field-label">County</label>}
              component={DropDownMenu}
              validate={required}
              name="school.county"
              options={CountyList}
              className="text-field w-input"
              disabled={readOnly || !termsAvailable}
            />
          </div>
        </div>
        <div className="field-combo-wrapper">
          <div className="field-combo">
            <Field
              label={<label htmlFor="First-3" className="field-label">State</label>}
              component={TextField}
              validate={required}
              name="school.state"
              className="text-field w-input"
              disabled={readOnly || !termsAvailable}
            />
          </div>
          <div className="field-combo">
            <Field
              label={<label htmlFor="Last-3" className="field-label">Zip</label>}
              component={TextField}
              validate={required}
              name="school.zip"
              className="text-field w-input"
              disabled={readOnly || !termsAvailable}
            />
          </div>
        </div>
        <div className="checklist-question">
          <label htmlFor="First-3" className="field-label">Grades Served (check all that apply)</label>
          <Field
            name="school.gradesServed"
            validate={required}
            options={sortGradesServed(gradesServed)}
            component={CheckboxGroup}
            className="checkbox-inline w-checkbox _7"
            disabled={readOnly || !termsAvailable}
          />
        </div>
        <div className="field-combo-wrapper">
          <div className="field-combo thirds">
            <Field
              label={<label htmlFor="First-3" className="field-label">Total Enrollment</label>}
              component={TextField}
              validate={required}
              parse={parseNumber}
              maxLength={4}
              name="numTotalEnrollment"
              className="text-field w-input"
              disabled={readOnly || !termsAvailable}
            />
          </div>
          <div className="field-combo thirds">
            <Field
              label={<label htmlFor="Last-3" className="field-label">Number of Students Receiving Reduced Lunch</label>}
              component={TextField}
              validate={[required, validateEnrollmentNumbers]}
              parse={parseNumber}
              maxLength={4}
              name="numReducedLunch"
              className="text-field w-input"
              disabled={readOnly || !termsAvailable}
            />
          </div>
          <div className="field-combo thirds">
            <Field
              label={<label htmlFor="Last-3" className="field-label">Number of Students Receiving Free Lunch</label>}
              component={TextField}
              validate={[required, validateEnrollmentNumbers]}
              parse={parseNumber}
              maxLength={4}
              name="numFreeLunch"
              className="text-field w-input"
              disabled={readOnly || !termsAvailable}
            />
          </div>
        </div>
      </div>
      <div className="demographics">
        <h4 className="purple-headline boxed-in">Staff Demographics</h4>
        <div className="field-combo-wrapper">
          <div className="field-combo">
            <Field
              label={<label htmlFor="First-3" className="field-label">Asian %</label>}
              component={TextField}
              validate={required}
              normalize={normalizePercent}
              format={formatPercent}
              name="demographicAsian"
              className="text-field w-input"
              disabled={readOnly || !termsAvailable}
            />
          </div>
          <div className="field-combo">
            <Field
              label={<label htmlFor="Last-3" className="field-label">Black/African American %</label>}
              component={TextField}
              validate={required}
              normalize={normalizePercent}
              format={formatPercent}
              name="demographicBlack"
              className="text-field w-input"
              disabled={readOnly || !termsAvailable}
            />
          </div>
        </div>
        <div className="field-combo-wrapper">
          <div className="field-combo">
            <Field
              label={<label htmlFor="First-3" className="field-label">Hispanic %</label>}
              component={TextField}
              validate={required}
              normalize={normalizePercent}
              format={formatPercent}
              name="demographicHispanic"
              className="text-field w-input"
              disabled={readOnly || !termsAvailable}
            />
          </div>
          <div className="field-combo">
            <Field
              label={<label htmlFor="Last-3" className="field-label">White %</label>}
              component={TextField}
              validate={required}
              normalize={normalizePercent}
              format={formatPercent}
              name="demographicWhite"
              className="text-field w-input"
              disabled={readOnly || !termsAvailable}
            />
          </div>
        </div>
      </div>
      <div className="coordinator">
        <h4 className="purple-headline boxed-in">Site Coordinator</h4>
        <div className="field-combo-wrapper">
          <div className="field-combo thirds">
            <Field
              label={<label htmlFor="First-3" className="field-label">First Name</label>}
              component={TextField}
              validate={required}
              name="school.siteCoordinator.firstName"
              className="text-field w-input"
              disabled={readOnly || !termsAvailable}
              autoComplete="input-unsupported"
            />
          </div>
          <div className="field-combo thirds">
            <Field
              label={<label htmlFor="Last-3" className="field-label">Last Name</label>}
              component={TextField}
              validate={required}
              name="school.siteCoordinator.lastName"
              className="text-field w-input"
              disabled={readOnly || !termsAvailable}
              autoComplete="input-unsupported"
            />
          </div>
          <div className="field-combo thirds">
            <Field
              label={<label htmlFor="Last-3" className="field-label">Job Title (optional)</label>}
              component={TextField}
              name="school.siteCoordinator.title"
              className="text-field w-input"
              disabled={readOnly || !termsAvailable}
              autoComplete="input-unsupported"
            />
          </div>
        </div>
        <div className="field-combo-wrapper">
          <div className="field-combo">
            <Field
              label={<label htmlFor="First-3" className="field-label">Phone</label>}
              component={TextField}
              validate={[required, phone]}
              normalize={normalizePhone}
              name="school.siteCoordinator.phone"
              className="text-field w-input"
              disabled={readOnly || !termsAvailable}
              autoComplete="input-unsupported"
            />
          </div>
          <div className="field-combo">
            <Field
              label={<label htmlFor="Last-3" className="field-label">Email</label>}
              component={TextField}
              validate={[required, email, uniqueTeachersAidTeamMember]}
              name="school.siteCoordinator.email"
              className="text-field w-input"
              placeholder="e.g. educator@school.com"
              disabled={readOnly || !termsAvailable}
              autoComplete="input-unsupported"
            />
          </div>
        </div>
      </div>
      <div className="coordinator">
        <h4 className="purple-headline boxed-in">Principal</h4>
        <div className="field-combo-wrapper">
          <div className="field-combo">
            <Field
              label={<label htmlFor="First-3" className="field-label">First Name</label>}
              component={TextField}
              validate={required}
              name="school.principal.firstName"
              className="text-field w-input"
              disabled={readOnly || !termsAvailable}
              autoComplete="input-unsupported"
            />
          </div>
          <div className="field-combo">
            <Field
              label={<label htmlFor="Last-3" className="field-label">Last Name</label>}
              component={TextField}
              validate={required}
              name="school.principal.lastName"
              className="text-field w-input"
              disabled={readOnly || !termsAvailable}
              autoComplete="input-unsupported"
            />
          </div>
        </div>
        <div className="field-combo-wrapper">
          <div className="field-combo">
            <Field
              label={<label htmlFor="First-3" className="field-label">Phone</label>}
              component={TextField}
              validate={[required, phone]}
              normalize={normalizePhone}
              name="school.principal.phone"
              className="text-field w-input"
              disabled={readOnly || !termsAvailable}
              autoComplete="input-unsupported"
            />
          </div>
          <div className="field-combo">
            <Field
              label={<label htmlFor="Last-3" className="field-label">Email</label>}
              component={TextField}
              validate={[required, email, uniqueTeachersAidTeamMember]}
              name="school.principal.email"
              className="text-field w-input"
              placeholder="e.g. educator@school.com"
              disabled={readOnly || !termsAvailable}
              autoComplete="input-unsupported"
            />
          </div>
        </div>
      </div>
      </>)}
      <ApplicationActions {...props} />
      {
        (invalid && submitFailed) && (
          <span
            style={{
              color: 'red',
            }}
          >
            You have required field(s) that are missing. Please see above.
          </span>
        )
      }
    </Form>
  );
};

ApplicationTeachersAidStepOne.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  termsAvailable: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  enums: PropTypes.object.isRequired,
  configTeachersAid: PropTypes.object.isRequired,
  termsForYear: PropTypes.array.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  data: PropTypes.object,
};

ApplicationTeachersAidStepOne.defaultProps = {
  readOnly: false,
  data: null,
};

export default React.memo(ApplicationTeachersAidStepOne);
