
const list = [
  {value: 'Austin', label: 'Austin'},
  {value: 'Brazoria', label: 'Brazoria'},
  {value: 'Chambers', label: 'Chambers'},
  {value: 'Galveston', label: 'Galveston'},
  {value: 'Fort Bend', label: 'Fort Bend'},
  {value: 'Harris', label: 'Harris'},
  {value: 'Liberty', label: 'Liberty'},
  {value: 'San Jacinto', label: 'San Jacinto'},
  {value: 'Trinity', label: 'Trinity'},
  {value: 'Walker', label: 'Walker'},
  {value: 'Waller', label: 'Waller'},
];

export default list;
