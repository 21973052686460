import gql from 'graphql-tag';

export default gql`
  query($districtId: ID = null, $schoolType: String = null) {
    allSchools(districtId: $districtId, schoolType: $schoolType) {
      id
      schoolId
      name
      address1
      address2
      city
      state
      zip
      county
      districtId
      districtName
      type
      gradesServed
      teachersAidSchoolNumber
      applicationsBackpackBuddy {
        applicationBackpackBuddyId
        submittedAt
        signedAt
        status
        isContinuation
      }
      applicationsSchoolMarket {
        applicationSchoolMarketId
        submittedAt
        signedAt
        status
        isContinuation
      }
      applicationsTeachersAid {
        applicationTeachersAidId
        submittedAt
        signedAt
        status
        isContinuation
      }
      teamMembersBackpackBuddy {
        teamMemberId
        firstName
        lastName
        title
        phone
        email
        type
      }
      teachers {
        teacherId
        firstName
        lastName
        title
        phone
        email
        gradesTaught
        proxyTeacher
        subjectsTaught
        numStudentsTaughtPerYear
      }
      primaryPointOfContact {
        teamMemberId
        firstName
        lastName
        title
        phone
        email
        type
      }
      siteCoordinator {
        teamMemberId
        firstName
        lastName
        title
        phone
        email
        type
      }
      principal {
        teamMemberId
        firstName
        lastName
        title
        phone
        email
        type
      }
    }
  }
`;