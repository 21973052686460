import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Form, Field } from 'redux-form';
import {
  required,
  email,
  teamPrincipalmatch,
  requiredTrue,
  educatorNameMatch,
} from '../../helpers';
import ApplicationActions from '../ApplicationActions';
import TextField from '../TextField';
import Checkbox from '../Checkbox';
import ProgramAgreement2024 from '../ProgramAgreement2024';
import ProgramAgreement from '../ProgramAgreement';
import NonDiscriminationStament from '../NonDiscriminationStament';
import GeneralAgreement2024 from '../GeneralAgreement2024';
import GeneralAgreement from '../GeneralAgreement';
import moment from 'moment';

const StepThreeForm = (props) => {
  const {
    handleSubmit,
    save,
    adminView,
    readOnly,
    termsAvailable,
    initialValues,
    invalid,
    submitFailed,
    data
  } = props;
  
  const principal = get(initialValues, 'school.teamMembersBackpackBuddy', []).find(({ type }) => type === 'PRINCIPAL');
  const principalEmail = get(principal, 'email', 'N/A');
  
  const isPrev2024 = () => {
    const date = moment(data.applicationBackpackBuddy.submittedAt);
    const data2 = data.applicationBackpackBuddy;
    const year = new Date().getFullYear() - 1;
    const isCont = data2.school.applicationsBackpackBuddy ?
    data2.school.applicationsBackpackBuddy.find(el => el.submittedAt && el.submittedAt.includes(year.toString()) && !el.isContinuation && el.status == "APPROVED"):
      false
    return (!!data.applicationBackpackBuddy.submittedAt && ((date.year() < 2024 || date.month() < 7) && date.year() <= 2024)) || isCont;
  };

  return (
    <Form onSubmit={handleSubmit(save)} id="email-form" className="backbackbuddy-form">
      {isPrev2024() ? <GeneralAgreement data={data.applicationBackpackBuddy} />: <GeneralAgreement2024 data={data.applicationBackpackBuddy}/>}
      <div className="field-combo-wrapper">
        <div className="field-combo">
          <Field
            label={
                (
                  <Fragment>
                    <label htmlFor="Last" className="field-label">
                      Agreement Sign
                    </label>
                  </Fragment>
                )
              }
            component={TextField}
            placeholder="Enter Your Name"
            validate={[required]}
            disabled={readOnly || !termsAvailable}
            name="nameSignFirst"
            className="text-field w-input"
          />
        </div>
        <div className="field-combo">
          <Field
            label={<strong>I Agree</strong>}
            name="agreeToProgramPurpose"
            validate={requiredTrue}
            component={Checkbox}
            className="checkbox-inline"
            disabled={readOnly || !termsAvailable}
            {...{
              style: {paddingBottom: 35}
            }}
          />
        </div>
      </div>
      <NonDiscriminationStament data={data.applicationBackpackBuddy} />
      <div className="field-combo-wrapper">
        <div className="field-combo">
          <Field
            label={
                (
                  <Fragment>
                    <label htmlFor="Last" className="field-label">
                      Nondiscrimination Sign
                    </label>
                  </Fragment>
                )
              }
            component={TextField}
            placeholder="Enter Your Name"
            validate={[required]}
            disabled={readOnly || !termsAvailable}
            name="nameSignSecond"
            className="text-field w-input"
          />
        </div>
        <div className="field-combo">
          <Field
            label={<strong>I Agree</strong>}
            name="agreeNondiscrimination"
            validate={requiredTrue}
            component={Checkbox}
            className="checkbox-inline"
            disabled={readOnly || !termsAvailable}
            {...{
              style: {paddingBottom: 35}
            }}
          />
        </div>
      </div>
      {isPrev2024() ? <ProgramAgreement />: <ProgramAgreement2024 />}
      <div className="field-combo-wrapper">
        <div className="field-combo">
          <Field
            label={
                (
                  <Fragment>
                    <label htmlFor="Last" className="field-label">
                      Program Sign
                    </label>
                  </Fragment>
                )
              }
            component={TextField}
            placeholder="Enter Your Name"
            validate={[required]}
            disabled={readOnly || !termsAvailable}
            name="nameSignThird"
            className="text-field w-input"
          />
        </div>
        <div className="field-combo">
          <Field
            label={<strong>I Agree</strong>}
            name="agreeToProgramResponsibilities"
            validate={requiredTrue}
            component={Checkbox}
            className="checkbox-inline"
            disabled={readOnly || !termsAvailable}
            {...{
              style: {paddingBottom: 35}
            }}
          />
        </div>
      </div>
      
      {
        !adminView && !readOnly && termsAvailable && (
          <Fragment>
            <div className="principal">
              <h4 className="purple-headline boxed-in">Principal Signature</h4>
              <p>
                Your Principal is required to provide an e-signature for this application to be
                considered. An email message will be sent to the Principal email that you provided.
                Your Principal must click the link and follow the instructions to provide
                their signature.
              </p>
              <div className="field-combo">
                <Field
                  label={
                    (
                      <Fragment>
                        <label htmlFor="First-3" className="field-label">
                          Verify Principal Email
                        </label>
                        <i>{principalEmail}</i>
                      </Fragment>
                    )
                  }
                  component={TextField}
                  placeholder="Enter Principal Email"
                  validate={[required, email, teamPrincipalmatch]}
                  name="verifyPrincipalEmail"
                  className="text-field w-input"
                />
              </div>
            </div>
          </Fragment>
        )
      }
      <ApplicationActions {...props} />
      <br />
      {
        (invalid && submitFailed) && (
          <span
            style={{
              color: 'red',
            }}
          >
            You have required field(s) that are missing. Please see above.
          </span>
        )
      }
    </Form>
  );
};

StepThreeForm.propTypes = {
  adminView: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  termsAvailable: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  invalid: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
};

StepThreeForm.defaultProps = {
  readOnly: false,
};

export default StepThreeForm;
